<template>
	<div style="padding: 30px">
		<el-form :inline="true" :model="formSearch" ref="formSearch" class="demo-form-inline">
			<el-form-item label="新闻名称">
				<el-input placeholder="请输入新闻名称" v-model="formSearch.newsName" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="分类名称">
				<el-select placeholder="分类名称" v-model="formSearch.newsSortId">
					<el-option label="全部" value=""></el-option>
					<el-option :label="item.name" :value="item.news_sort_id" v-for="(item, index) in sortAll"
						:key="index"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="是否发布" style="display: none">
				<el-select placeholder="是否发布" v-model="formSearch.state">
					<el-option value="0" label="全部"></el-option>
					<el-option value="1" label="是"></el-option>
					<el-option value="2" label="否"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getTableDate(1)">查询</el-button>
			</el-form-item>
		</el-form>
		<el-button type="primary" @click="handleAdd">添加新闻</el-button>
		<el-dialog title="新闻添加" :visible.sync="dialogFormVisible" width="800px ">
			<el-form :model="formInfo" ref="formInfo" :rules="rules">
				<el-form-item label="新闻名称" :label-width="formLabelWidth" prop="newsName">
					<el-input v-model="formInfo.newsName" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="新闻分类" :label-width="formLabelWidth" prop="newsSortId">
					<el-select v-model="formInfo.newsSortId" placeholder="请选择新闻分类" style="width: 100%">
						<el-option :label="item.name" :value="item.news_sort_id" v-for="(item, index) in sortAll"
							:key="index"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="新闻排序" :label-width="formLabelWidth" prop="sort">
					<el-input type="number" v-model="formInfo.sort" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="是否发布" :label-width="formLabelWidth" style="display: none">
					<el-radio v-model="formInfo.state" label="1">是</el-radio>
					<el-radio v-model="formInfo.state" label="2">否</el-radio>
				</el-form-item>
				<el-form-item label="新闻简介" :label-width="formLabelWidth" prop="newsSynopsis">
					<el-input v-model="formInfo.newsSynopsis" type="textarea" :autosize="{ minRows: 4 }"></el-input>
				</el-form-item>
				<el-form-item label="新闻内容" :label-width="formLabelWidth" prop="newContent">
					<!-- <editor-wang @change="changeWang" ref="mychild"></editor-wang> -->
					<tinymce ref="mychild"></tinymce>
					<!-- <el-button @click="postvalue">打印</el-button> -->
				</el-form-item>
				<el-form-item label="新闻封面" :label-width="formLabelWidth">
					<el-upload class="avatar-uploader" :action="pictureUpload" :show-file-list="false"
						:auto-upload="false" :on-change="handleChange">
						<img v-if="formInfo.pictureVisable" :src="formInfo.pictureUrl" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</div>
		</el-dialog>
		<el-table :data="tableData" border stripe style="width: 100%; margin-top: 20px">
			<el-table-column prop="news_name" label="新闻名称" min-width="280"></el-table-column>
			<el-table-column prop="news_sort_id" label="分类名称" :formatter="nameChange" min-width="100"></el-table-column>
			<el-table-column label="封面图" min-width="80">
				<template slot-scope="scope">
					<img :src="imgRealUrl(scope)" style="height: 50px" />
				</template>
			</el-table-column>
			<el-table-column prop="sort" label="排序" min-width="80"></el-table-column>
			<el-table-column prop="state" label="是否发布" :formatter="stateChange" min-width="80"></el-table-column>
			<el-table-column label="操作" width="300">
				<template slot-scope="scope">
					<el-button size="mini"  plain type="success" @click="preview(scope)" style="margin-left: 10px;">预览</el-button>
					<el-button size="mini" type="primary" plain v-if="scope.row.state != 1" @click="handleReport(scope, 1)">发布
					</el-button>
					<el-button size="mini" type="info" plain v-else @click="handleReport(scope, 2)">取消</el-button>
					<el-button size="mini" type="warning" plain @click="handleEdit(scope)">编辑</el-button>
					<el-button size="mini" type="danger" plain @click="handleDelete(scope)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination style="margin-top: 10px" background @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page="tablePageInfo.currentPage"
			:page-sizes="[10, 20, 50, 100]" :page-size="tablePageInfo.pageSize"
			layout="total, sizes, prev, pager, next, jumper" :total="tablePageInfo.total">
		</el-pagination>
		
		<el-dialog title="预览" width="1100px" :visible.sync="dialogPreviewVisible">
			<NewsContentPreview ref="preview"></NewsContentPreview>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogPreviewVisible = false">关闭</el-button>
			</div>
		</el-dialog>
		
	</div>
</template>

<script>
	import Tinymce from "@components/tinymce.vue";
	import NewsContentPreview from "@components/newsContentPreview";
	export default {
		components: {
			Tinymce: Tinymce,
			NewsContentPreview
		},
		data() {
			return {
				tableData: [],
				tablePageInfo: {
					pageSize: 10, //每页的条数
					total: 0, //总条数
					currentPage: 1, //当前页
				},
				formSearch: {
					newsName: "",
					state: "",
					newsSortId: "",
					page: 1,
					limit: 10,
				},
				sortAll: [],
				dialogFormVisible: false,
				pictureUpload: "admin/Article/uploadFile", //上传图片接口
				formInfo: {
					newsName: "",
					newsSortId: "", //新闻分类id
					sort: "",
					state: "2",
					newsSynopsis: "", //新闻简介
					newContent: "", //新闻内容
					pictureUrl: "", //查看的图片url
					pictureVisable: false, //查看的图片url
					pictures: "", //上传的图片
				},
				rules: {
					name: [{
						required: true
					}],
					news_sort_id: [{
						required: true
					}],
					sort: [{
						required: true
					}],
					newsSynopsis: [{
						required: true
					}], //新闻简介
					newContent: [{
						required: true
					}], //新闻内容
					pictureUrl: [{
						required: true
					}],
				},
				formLabelWidth: "120px",
				buttonFun: "addArticle",
				dialogPreviewVisible:false,
			};
		},
		methods: {
			getTableDate: function(res) {
				var that = this;
				if (res != "") {
					that.formSearch.page = 1;
				} //点击查询，恢复第一页
				this.$axios
					.post(this.baseUrl + "admin/article/searchArticle", that.formSearch)
					.then((response) => {
						if (response.data.code != "200") {
							that.$alert(response.data.msg, "提示", {
								confirmButtonText: "确定",
							});
						} else {
							that.tablePageInfo.total = response.data.data.count;
							that.tableData = response.data.data.res;
						}
					})
					.catch(function(error) {
						window.console.log(error);
					}); //ajax请求失败
			},
			//选择图片，图片变化
			handleChange(event) {
				this.formInfo.pictures = event.raw;
				var testmsg = /^image\/(jpeg|png|jpg)$/.test(event.raw.type);
				if (!testmsg) {
					this.$message.error("上传头像图片只能是 jpeg|png|jpg 格式!");
					return false;
				}
				console.log(event.url);
				let url = "";
				if (window.createObjectURL != undefined) {
					url = window.createObjectURL(event.raw);
				} else if (window.URL != undefined) {
					url = window.URL.createObjectURL(event.raw);
				} else if (window.webkitURL != undefined) {
					url = window.webkitURL.createObjectURL(event.raw);
				}
				console.log(url);
				this.formInfo.pictureVisable = true;
				this.formInfo.pictureUrl = url;
			},
			//增加新闻
			handleAdd() {
				(this.buttonFun = "addArticle"), (this.dialogFormVisible = true);
				this.formInfo = {
					newsName: "",
					newsSortId: "", //新闻分类id
					sort: "",
					state: "2",
					newsSynopsis: "", //新闻简介
					newContent: "", //新闻内容
					pictureUrl: "", //查看的图片url
					pictureVisable: false, //查看的图片url
					pictures: "", //上传的图片
				};
				if (this.$refs["formInfo"] !== undefined) {
					this.$refs["formInfo"].resetFields();
				}
				this.$nextTick(() => {
					this.$refs.mychild.myValue = "";
				});
			},
			//编辑新闻
			handleEdit(scope) {
				(this.buttonFun = "updateArticle"),
				(this.formInfo = {
					newsId: scope.row.news_id,
					newsName: scope.row.news_name,
					newsSortId: scope.row.news_sort_id, //新闻分类id
					sort: scope.row.sort,
					state: scope.row.state.toString(),
					newsSynopsis: scope.row.news_synopsis, //新闻简介
					newContent: scope.row.news_content, //新闻内容
					pictureUrl: this.imgRealUrl(scope),
					pictures: "",
					pictureVisable: true, //查看的图片url
				});
				this.$nextTick(() => {
					this.$refs.mychild.myValue = scope.row.news_content;
				});
				this.dialogFormVisible = true;
			},
			postvalue() {
				console.log(this.$refs.mychild.myValue);
				console.log(this.newContent);
			}, //子组件fuwenben向父组件传值
			//删除新闻

			handleDelete(scope) {
				var that = this;
				this.$confirm("确定删除吗?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).
				then(() => {
					that.$axios.get(this.baseUrl + "admin/article/deleteArticle?newsId=" + scope.row.news_id).
					then((response) => {
						if (response.data.code == "200") {
							that.getTableDate();
						}
						that.$alert(response.data.msg, "提示", {
							confirmButtonText: "确定"
						});
					}).catch(function(error) {
						window.console.log(error);
					}); //ajax请求失败
				}).catch(() => {})
			},
			//发布新闻
			handleReport(scope, state) {
				var that = this;
				this.$axios
					.get(
						this.baseUrl +
						"admin/article/ReleaseArticle?newsId=" +
						scope.row.news_id +
						"&state=" +
						state
					)
					.then((response) => {
						if (response.data.code == "200") {
							that.getTableDate();
						}
						that.$alert(response.data.msg, "提示", {
							confirmButtonText: "确定"
						});
					})
					.catch(function(error) {
						window.console.log(error);
					}); //ajax请求失败
			},
			//增加编辑提交表单
			submitForm() {
				var that = this;
				let param = new FormData();
				that.formInfo.newContent = this.$refs.mychild.myValue;
				if (this.buttonFun == "updateArticle") {
					param.append("newsId", that.formInfo.newsId);
				}
				param.append("pictures", that.formInfo.pictures);
				param.append("newsName", that.formInfo.newsName),
					param.append("newsSortId", that.formInfo.newsSortId), //新闻分类id
					param.append("sort", that.formInfo.sort),
					param.append("state", parseInt(that.formInfo.state)),
					param.append("newsSynopsis", that.formInfo.newsSynopsis), //新闻简介
					param.append("newContent", this.$refs.mychild.myValue), //新闻内容
					this.$refs["formInfo"].validate((valid) => {
						if (valid) {
							this.$axios
								.post(this.baseUrl + "admin/article/" + this.buttonFun, param, {
									headers: {
										"Content-Type": "multipart/form-data"
									},
								})
								.then((response) => {
									if (response.data.code == "200") {
										that.dialogFormVisible = false;
										that.getTableDate();
									}
									that.$alert(response.data.msg, "提示", {
										confirmButtonText: "确定",
									});
								})
								.catch(function(error) {
									window.console.log(error);
								}); //ajax请求失败
						} else {
							console.log("error submit!!");
							return false;
						}
					});
			},
			handleSizeChange(val) {
				this.formSearch.limit = val;
				this.getTableDate("");
			}, //每页条数发生改变
			handleCurrentChange(val) {
				this.formSearch.page = val;
				this.getTableDate("");
			}, //翻页
			stateChange(row) {
				if (row.state == 1) {
					return "是";
				} else {
					return "否";
				}
			}, //是否发布
			nameChange(row) {
				for (var i = 0; i < this.sortAll.length; i++) {
					if (this.sortAll[i].news_sort_id == row.news_sort_id) {
						return this.sortAll[i].name;
					}
				}
			},
			imgRealUrl(scope) {
				return this.baseUrl2 + "uploads/" + scope.row.picture;
			}, //图片真实地址
			preview(scope){
				this.dialogPreviewVisible=true;
				var that=this;
				setTimeout(function(){that.$refs.preview.preview(scope.row.news_id);},200)
			},
		},
		beforeMount: function() {
			var that = this;
			this.$axios
				.post(this.baseUrl + "admin/article/searchSort")
				.then((response) => {
					if (response.data.code != "200") {
						that.$alert(response.data.msg, "提示", {
							confirmButtonText: "确定"
						});
					} else {
						that.sortAll = response.data.data.res;
					}
				})
				.catch(function(error) {
					window.console.log(error);
				}); //ajax请求失败
			this.getTableDate("");
		},
		watch: {
			watchvlue() {
				this.newContent = this.$refs.mychild.myValue;
			},
		},
	};
</script>
<style scoped>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.el-icon-plus {
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}
</style>
