<template>
	<div style="text-align: center;background-color: #EBEEF5;padding:0 5%">
		<div style="text-align: left;padding:40px;margin:10px;background-color: #fff;">
			<div style="margin-left: 40px;">
				<div id="tenderDiv">
					<h3 style="text-align: center;">{{info.news_name}}</h3>
					<h4 style="text-align: center;color:#909399;"><span style="margin-right: 50px;">{{info.release_time}}</span><span></span></h4>
				</div>
				<div v-html="info.news_content" id="wangTable">
				</div>
			</div>
		</div>
	</div>
</template>
<style>
	#wangTable img{max-width: 100%;}
	#wangTable table{
		width:100%;
		border-top:#DCDFE6 1px solid;
		border-left:#DCDFE6 1px solid;
	}
	#wangTable table td,#wangTable table th{
		border-bottom:#DCDFE6 1px solid;
		border-right:#DCDFE6 1px solid;
		padding: 10px;
		height: 25px;
		line-height: 25px;
	}
</style>
<script>
export default {
	name:'newsContentPreview',
  data() {
    return {
			info:'',
    };
  },
  mounted() {},
	methods:{
		preview(id){
			var that=this;
			this.$axios.post(this.baseUrl+'admin/article/searchArticle?newsId='+id).then(response=>{
				if(response.data.code!='200'){
					that.$alert(response.data.msg, '提示', {confirmButtonText: '确定'});
				}else{
					that.info=response.data.data.res[0];
					console.log(response);
				}
			}).catch(function (error) {window.console.log(error)});//ajax请求失败
		}
	}
};
</script>